<script setup>
import lozad from 'lozad';

const runtimeConfig = useRuntimeConfig();
const trackerCode = '21917371';
const route = useRoute();

useHead({
  bodyAttrs: {
    class: 'antialiased font-sans',
  },
});

if (process.client) {
  document.documentElement.setAttribute('lang', navigator.language);

  setTimeout(() => {
    const privadoScript = document.createElement('script');
    privadoScript.setAttribute('src', 'https://www.cdn.privado.ai/6c578becd5ad4ec49a684a269aeb7cf6.js');
    privadoScript.setAttribute('type', 'text/javascript');
    document.body.appendChild(privadoScript);
  }, 3000);

  const hubspotScript = document.createElement('script');
  hubspotScript.setAttribute('src', `//js.hs-scripts.com/${trackerCode}.js`);
  hubspotScript.setAttribute('id', 'hs-script-loader');
  hubspotScript.setAttribute('type', 'text/javascript');
  document.body.appendChild(hubspotScript);

  function googleAnalyticsFunc() {
    return (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-WZ6GQ3Z');
  }

  const googleAnalyticsScript = document.createElement('script');
  googleAnalyticsScript.textContent = googleAnalyticsFunc();
  document.head.appendChild(googleAnalyticsScript);

  const googleAnalyticsNoScript = document.createElement('noscript');
  const gaIframe = document.createElement('iframe');
  gaIframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-WZ6GQ3Z');
  gaIframe.height = '0';
  gaIframe.width = '0';
  gaIframe.style.display = 'none';
  gaIframe.style.visibility = 'hidden';
  googleAnalyticsNoScript.appendChild(gaIframe);
  const bodyFirstChild = document.body.firstChild;
  bodyFirstChild.parentNode.insertBefore(googleAnalyticsNoScript, bodyFirstChild);

  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['setPath', window.location.pathname + window.location.search]);

  function trackPageView() {
    _hsq.push(['setPath', window.location.pathname + window.location.search]);
    _hsq.push(['trackPageView']);
  }

  watch(
    () => route,
    () => {
      trackPageView();

      // const media = document.querySelectorAll('video, img');
      // const observer = lozad(media);

      const observer = lozad('.lozad', {
        rootMargin: '500px',
      });
      observer.observe();

      const images = document.getElementsByTagName('img');
      for (let i = 0; i < images.length; i++) {
        let image = images[i];
        if (image.hasAttribute('src')) {
          image.src.replace(/http:/g, 'https:');
        }
        if (image.hasAttribute('srcset')) {
          image.srcset.replace(/http:/g, 'https:');
        }
      }
    },
    {
      immediate: true,
      deep: true,
    },
  );

  onMounted(() => {
    var prevScrollPos = window.pageYOffset;

    window.addEventListener('scroll', function () {
      var currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        document.getElementById('navbar').style.top = '0';
        document.getElementById('body').classList.add('pt-[68px]');
        document.getElementById('body').classList.add('md:pt-[116px]');
        document.getElementById('body').classList.add('lg:pt-[112px]');
        document.getElementById('body').classList.add('xl:pt-[124px]');
      } else {
        document.getElementById('navbar').style.top = '-168px';
        document.getElementById('body').classList.remove('pt-[68px]');
        document.getElementById('body').classList.remove('md:pt-[116px]');
        document.getElementById('body').classList.remove('lg:pt-[112px]');
        document.getElementById('body').classList.remove('xl:pt-[124px]');
      }

      prevScrollPos = currentScrollPos;
    });
  });
}

// const showPopupCookie = useCookie('showPopupCookie');

// showPopupCookie.value = 'true';

// const showVideoPopup = ref(false);

// onMounted(() => {
//   if (showPopupCookie.value === 'true') {
//     setTimeout(() => {
//       showVideoPopup.value = true;
//     }, 1000);
//   }
// });

// function closeVideoPopup() {
//   showVideoPopup.value = false;
//   showPopupCookie.value = 'false';
// }
const LazyFooter = defineAsyncComponent(() => import('./../components/Footer.vue'));
</script>

<template>
  <div id="root-container" class="flex min-h-screen flex-col font-hk_grotesk">
    <Header id="navbar" class="fixed top-0 z-30 w-full shadow-sm transition-[top] duration-[400ms]" />
    <div
      id="body"
      class="grow pt-[68px] transition-[padding] duration-[400ms] md:pt-[116px] lg:pt-[112px] xl:pt-[124px]"
    >
      <slot />
    </div>
    <LazyFooter />
  </div>
</template>

<style></style>
